<template>
  <keep-alive :include="cacheList">
    <router-view></router-view>
  </keep-alive>
</template>

<script>
export default {
  data(){
    return{
      cacheList: ['orderList'], //缓存组件name 页面name
    }
  },
}
</script>

<style>

</style>